.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.containerForIconEventType {
  position: relative;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.containerForIconEventType::after {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 50%;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgb(230, 231, 234);
}


.iconForEventType {
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 2;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 4px;
  background-color: rgb(229, 238, 255);
  border-top-color: #d9e5fd !important;
  /*color: rgb(24, 60, 129);*/
  color: #c5d8fe;
  width: 32px;
  height: 32px;
}


.zp_mIICq {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #fff !important;
  border-shadow:  0 3px 6px 0 rgba(0, 0, 0, 0.09);;

}


.sequenceStepAmount::before {
  position: relative;
  content: "";
  display: inline-block;
  top: 0;
  width: 4px;
  height: 4px;
  margin: 3px 8px;
  border-radius: 50%;
  background-color: #474747;
  color: #474747;
}

.step-sequence-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


/*.rdw-option-wrapper {*/
/*  color: #333;*/
/*  background: transparent;*/
/*  padding: 13px 10px !important;*/
/*  border: 2px solid #d9d9d9;*/
/*  border-radius: 5px !important;*/
/*  margin: 5px;*/
/*  transition: all 0.3s;*/
/*  box-sizing: border-box;*/
/*}*/

/*.rdw-option-wrapper:hover {*/
/*  color: #333;*/
/*  border-color: #1890ff;*/
/*  cursor: pointer;*/
/*}*/

/*.rdw-option-active {*/
/*  border-color: #1890ff;*/
/*  color: #1890ff;*/
/*}*/

/*.rdw-dropdown-wrapper {*/
/*  border: 1px solid #d9d9d9;*/
/*  border-radius: 10px;*/
/*  box-sizing: border-box;*/
/*}*/

/*.rdw-dropdown-optionwrapper {*/
/*  padding: 15px;*/
/*  color: #333;*/
/*  border: 1px solid #d9d9d9;*/
/*  transition: all 0.3s;*/
/*  box-sizing: border-box;*/
/*}*/

/*.rdw-dropdown-optionwrapper:hover {*/
/*  color: #333;*/
/*  border-color: #1890ff;*/
/*  cursor: pointer;*/
/*}*/

/*.rdw-dropdown-option-active {*/
/*  border-color: #1890ff;*/
/*  color: #1890ff;*/
/*}*/


/*.rdw-editor-main {*/
/*  border: 1px solid #d9d9d9;*/
/*  border-radius: 4px;*/
/*  padding: 10px;*/
/*  transition: all 0.3s;*/
/*  box-sizing: border-box;*/
/*  min-height: 150px; !* define height as per your requirement *!*/
/*}*/

/*.rdw-editor-main:hover, .rdw-editor-main:focus {*/
/*  border-color: #1890ff;*/
/*  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);*/
/*  outline: 0;*/
/*}*/

/*.rdw-editor-main:active {*/
/*  border-color: #1890ff;*/
/*}*/

/*.public-DraftStyleDefault-block {*/
/*  margin: 0.3em 0 !important;*/
/*}*/
