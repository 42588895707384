.online {
    position: relative;
    top: 30px;
    left: 35px;
    width: 13px;
    height: 13px;
    background-color: #8BC34A;
    border-radius: 13px;
    border: 3px solid #FAFAFA;
}

.desc-contact {
    height: 43px;
    width:50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.timer {
    margin-left: 15%;
    font-family:'Open Sans', sans-serif;
    font-size: 11px;
    padding: 3px 8px;
    color: #BBB;
    background-color: #FFF;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
}

.chat .messages-chat {
    padding: 25px 35px;
}

.chat .messages-chat .message {
    display:flex;
    align-items: center;
    margin-bottom: 8px;
}

.chat .messages-chat .message .photo {
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.chat .text {
    white-space: pre-line;
    max-width: 500px;
}

.chat .messages-chat .text {
    margin: 0 0px;
    background-color: #f6f6f6;
    padding: 15px;
    border-radius: 12px;
}

.text-only {
    margin-left: 0px;
}

.time {
    font-size: 10px;
    color:lightgrey;
    margin-bottom:10px;
    margin-left: 0px;
}

.response-time {
    float: right;
    margin-right: 0px !important;
    margin-left: auto;
}

.response {
    float: right;
    margin-right: 0px !important;
    margin-left:auto; /* flexbox alignment rule */
}

.response .text {
    background-color: #e3effd !important;

}



.write-message {
    border:none !important;
    width:60%;
    height: 50px;
    margin-left: 20px;
    padding: 10px;
}


.clickable {
    cursor: pointer;
}
